import {
  FaBook,
  FaBriefcase,
  FaBusinessTime,
  FaCog,
  FaCommentDollar,
  FaEnvelope,
  FaFacebookMessenger,
  FaLaptopHouse,
  FaMoneyCheck,
  FaTags,
  FaUser,
  FaUserFriends,
  FaUserTie,
  FaUserCog,
  FaCreditCard,
  FaPhoneAlt,
  FaMap,
  FaListAlt,
  FaUsersCog,
  FaUserCheck,
} from "react-icons/fa";
import { MdCancel, MdGroupAdd, MdMenu } from "react-icons/md";
export const sideMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },

  {
    to: "/conversations",
    label: "Conversations",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/leads",
    label: "Lead",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/jobs",
    label: "Jobs",
    Icon: FaBusinessTime,
    task: "List Job",
    permission: ["ALL"],
  },
  {
    to: "/reports/job_progress_report",
    label: "Progress",
    Icon: FaBusinessTime,
    task: "List Job",
    permission: ["ALL"],
  },
  {
    to: "/industry_database",
    label: "Industry Database",
    Icon: FaBusinessTime,
    task: "Industry Database",
    permission: ["ALL"],
  },
  {
    to: "/dids_inventory",
    label: "DIDs Inventory",
    Icon: FaBusinessTime,
    task: "Industry Database",
    permission: ["ALL"],
  },
  {
    to: "/settings",
    label: "Settings",
    Icon: FaCog,
    task: "Settings",
    permission: ["ALL"],
    children: [
      {
        to: "quote_pricing",
        label: "Quoting",
        Icon: FaCommentDollar,
        task: "Service Cost",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "allowedIp",
        label: "Allowed IP",
        Icon: FaLaptopHouse,
        task: "List Allowed IP",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "merchants",
        label: "Merchants",
        Icon: FaUserTie,
        task: "List Merchant",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "users",
        label: "User",
        Icon: FaUser,
        task: "List User",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "vendors",
        label: "Vendors",
        Icon: FaUser,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "vendors_industry",
        label: "Vendors Industry",
        Icon: FaUser,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "roles",
        label: "User Roles",
        Icon: FaUserCog,
        task: "Administration",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "tasks",
        label: "User Permissions",
        Icon: FaUserCog,
        task: "Administration",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "tags",
        label: "Tags",
        Icon: FaTags,
        task: "List Tag",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "emailTemplates",
        label: "Email Templates",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "smsTemplates",
        label: "SMS Templates",
        Icon: FaFacebookMessenger,
        task: "List SMS Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "paymentGateway",
        label: "Payment Gateway",
        Icon: FaMoneyCheck,
        task: "List Payment Gateway",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "providers",
        label: "Payment Providers",
        Icon: FaMoneyCheck,
        task: "List Provider",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "jobStatus",
        label: "Job Status",
        Icon: FaBriefcase,
        task: "List Job Status",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "declineReasons",
        label: "Decline Reason",
        Icon: MdCancel,
        task: "List Decline Reason",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },

      {
        to: "cardtype",
        label: "Card Types",
        Icon: FaCreditCard,
        task: "Card Types",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "industries",
        label: "Industry",
        Icon: FaCreditCard,
        task: "List Industry",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "jobCategory",
        label: "Industry Category",
        Icon: FaCreditCard,
        task: "List Job Category",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "generalSettings",
        label: "General Settings",
        Icon: FaCreditCard,
        task: "Administration",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "restricted_words",
        label: "Restricted Words",
        Icon: FaCreditCard,
        task: "Administration",
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "cron_functions",
        label: "Cron Functions",
        Icon: FaCreditCard,
        task: "Administration",
        permission: ["SUPER ADMINISTRATOR"],
      },
    ],
  },
  {
    to: "/calendar",
    label: "Calendar",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/map",
    label: "Map",
    Icon: FaCommentDollar,
    task: "Map",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/realtime",
    label: "Real Time",
    Icon: FaCommentDollar,
    task: "Get Real Time Job Recording",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/reports",
    label: "Reports",
    Icon: FaBook,
    task: "Reports",
    permission: ["ALL", "SUPER ADMINISTRATOR"],
  },
  {
    to: "/callcenter",
    label: "Call Center",
    Icon: FaPhoneAlt,
    task: "List Call Center",
    permission: ["SUPER ADMINISTRATOR", "BM"],
    children: [
      {
        to: "call_centers",
        label: "Call Centers",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "campaigns",
        label: "Campaigns",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "inbound_groups",
        label: "Ring Groups",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "dids",
        label: "DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "audio_files",
        label: "Audio Files",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "call_menu",
        label: "Call Menu",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "spam_numbers",
        label: "Spam Numbers",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "reports",
        label: "Report",
        Icon: FaBook,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
        children: [
          {
            to: "agent_performance_report",
            label: "Agent Performance",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["SUPER ADMINISTRATOR", "BM"],
          },
          {
            to: "call_summary_report",
            label: "Call Summary",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["SUPER ADMINISTRATOR", "BM"],
          },
          {
            to: "call_logs",
            label: "Call Logs",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["SUPER ADMINISTRATOR", "BM"],
          },
          {
            to: "inbound_forwarding_report",
            label: "Inbound Forwarding",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["SUPER ADMINISTRATOR", "BM"],
          },
          {
            to: "missedcall_report",
            label: "Missed Call Report",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["SUPER ADMINISTRATOR", "BM"],
          },
        ],
      },
    ],
  },
  {
    to: "/geolocation",
    label: "Geo Location",
    Icon: FaCog,
    task: "Geo Location",
    permission: ["ALL"],
    children: [
      {
        to: "locations_list",
        label: "Locations",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "scrub_domains",
        label: "Scrub Domains",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "geo_location_report",
        label: "Report",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "check_distance",
        label: "Check Distance",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "geo_states",
        label: "States",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "geo_cities",
        label: "Cities",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
    ],
  },
  {
    to: "/nation_site",
    label: "National Sites",
    Icon: FaCog,
    task: "List National Site",
    permission: ["ALL"],
    children: [
      {
        to: "sites",
        label: "Site Listing",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "nation_site_zips",
        label: "Nation Site Zips",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
    ],
  },
  {
    to: "/domain_center",
    label: "Domain Center",
    Icon: FaCog,
    task: "Domain Center",
    permission: ["ALL"],
    children: [
      {
        to: "purchased_domains",
        label: "Purchased Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_ranking",
        label: "Domain Ranking",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "namesilo_domains_report",
        label: "Namesilo Domain Rpt",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "expired_domains",
        label: "Expired Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "inteliquent_numbers",
        label: "Inteliquent Numbers",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "gsc_email_accounts",
        label: "Email Accounts",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "runners",
        label: "Domain Runners",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "detach_domains",
        label: "Detach Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_active_report",
        label: "Domains Active Report",
        Icon: FaListAlt,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_index_report",
        label: "Domains Index Report",
        Icon: FaListAlt,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "pull_to_server",
        label: "Pull to Server",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "cloudflare_accounts",
        label: "Cloudflare Accounts",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_numbers",
        label: "Domain Numbers",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "pending_domains_phone",
        label: "Pending Phones",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "virtual_host",
        label: "Virtual Hosts",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "list_alternate_area_codes",
        label: "Alternate Area Codes",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "servers",
        label: "Server",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "purchase_registrar",
        label: "Purchased Registrar",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "purchase_registrar_accounts",
        label: "Registrar Accounts",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "tlds",
        label: "TLDs",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "registrar",
        label: "Registrar",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "buying",
        label: "Buying",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },

      {
        to: "testing_domains",
        label: "Testing Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "testing_links",
        label: "Testing Links",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "multiple_domains",
        label: "Multiple Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_location_report",
        label: "Domains Location",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },

      {
        to: "batch_report",
        label: "Batches",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
    ],
  },
  {
    to: "/domain_rank",
    label: "Domain Rank",
    Icon: FaCommentDollar,
    task: "Domain Center",
    permission: ["SUPER ADMINISTRATOR", "BM"],
    children: [
      {
        to: "domains",
        label: "Domains",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "keywords",
        label: "Keywords",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "batches_list",
        label: "Batches",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "searches",
        label: "Searches",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
    ],
  },
  {
    to: "/cloudflare",
    label: "Cloudflare",
    Icon: FaCommentDollar,
    task: "Domain Center",
    permission: ["SUPER ADMINISTRATOR", "BM"],
    children: [
      {
        to: "generate_email",
        label: "Generate Emails",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "forward_email",
        label: "Forward Emails",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "email_prefix",
        label: "Email Prefix",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "domain_email_list",
        label: "Domain Emails List",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
    ],
  },
  {
    to: "/google_console_list",
    label: "Google Credentials",
    Icon: FaCog,
    task: "Domain Center",
    permission: ["ALL"],
  },
];

export const verifiedMenu = [
  {
    to: "/verified_lead/leads",
    label: "Leads",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/verified_lead/deals",
    label: "Deals",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/crmreports",
    label: "Reports",
    Icon: FaBook,
    task: "List Reports",
    permission: ["ALL"],
  },
  {
    to: "/calendar_events",
    label: "Calendar",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/verified_lead/workflow",
    label: "Workflow Report",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/customers",
    label: "Customers",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/products",
    label: "Products",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/settings",
    label: "Settings",
    Icon: FaUsersCog,
    task: "Settings",
    permission: ["ALL"],
    children: [
      {
        to: "inventory",
        label: "Inventory",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "vendor_price",
        label: "Vendor Price",
        Icon: FaCommentDollar,
        task: "Vendor Price",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "commission_spread",
        label: "Commission Spread",
        Icon: FaCommentDollar,
        task: "Commission Spread",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "inbox",
        label: "Inbox",
        Icon: FaEnvelope,
        task: "List Reports",
        permission: ["ALL"],
      },
      {
        to: "docusign_templates",
        label: "Docusign Templates",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "workflow_status",
        label: "Workflow",
        Icon: FaCommentDollar,
        task: "Scheduler",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "lead_source",
        label: "Lead Source",
        Icon: MdMenu,
        task: "List Lead",
        permission: ["ALL"],
      },
      {
        to: "administration",
        label: "Administration",
        Icon: FaCog,
        task: "List Lead",
        permission: ["ALL"],
        children: [
          {
            to: "users",
            label: "Users & Groups",
            Icon: MdGroupAdd,
            task: "List User",
            permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
          },
          {
            to: "roles",
            label: "User Roles",
            Icon: FaUserCog,
            task: "Administration",
            permission: ["SUPER ADMINISTRATOR"],
          },
          {
            to: "permissions",
            label: "Permissions",
            Icon: FaUserCog,
            task: "Administration",
            permission: ["SUPER ADMINISTRATOR"],
          },
          {
            to: "lead_status",
            label: "Lead Status",
            Icon: FaUserCheck,
            task: "List Lead Status",
            permission: ["ALL"],
          },
          {
            to: "emailTemplates",
            label: "Email & SMS",
            Icon: FaEnvelope,
            task: "List Email",
            permission: ["ALL"],
          },
        ],
      },
    ],
  },
  // {
  //   to: "/lead_management",
  //   label: "Lead Management",
  //   Icon: FaUsersCog,
  //   task: "Settings",
  //   permission: ["ALL"],
  //   children: [
  //     {
  //       to: "lead_source",
  //       label: "Lead Source",
  //       Icon: MdMenu,
  //       task: "List Lead",
  //       permission: ["ALL"],
  //     },
  //     {
  //       to: "distribution_program",
  //       label: "Distribution Program",
  //       Icon: MdMenu,
  //       task: "List User",
  //       permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
  //     },
  //   ],
  // },
  // {
  //   to: "/administration",
  //   label: "Administration",
  //   Icon: FaCog,
  //   task: "List Lead",
  //   permission: ["ALL"],
  //   children: [
  //     {
  //       to: "users",
  //       label: "Users & Groups",
  //       Icon: MdGroupAdd,
  //       task: "List User",
  //       permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
  //     },
  //     {
  //       to: "lead_status",
  //       label: "Lead Status",
  //       Icon: FaUserCheck,
  //       task: "List Lead Status",
  //       permission: ["ALL"],
  //     },
  //     // {
  //     //   to: "lead_actions",
  //     //   label: "Lead Actions",
  //     //   Icon: RiUserSharedFill,
  //     //   task: "List Lead",
  //     //   permission: ["ALL"],
  //     // }

  //     // {
  //     //   to: "lead_source",
  //     //   label: "Actions & Statuses",
  //     //   Icon: FaUserFriends,
  //     //   task: "List Lead",
  //     //   permission: ["ALL"],
  //     // },
  //     {
  //       to: "email_sms",
  //       label: "Email & SMS",
  //       Icon: FaEnvelope,
  //       task: "List Email",
  //       permission: ["ALL"],
  //     },
  //   ],
  // },
];
