import React, { Suspense } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout, Loader } from "./components";
import { useSelector } from "react-redux";
import {
  Login,
  AllowedIp,
  Merchant,
  Tag,
  Lead,
  Job,
  User,
  EmailTemplate,
  PaymentGateway,
  JobStatus,
  DeclineReason,
  SMSTemplate,
  LoginLogs,
  Roles,
  CardType,
  Industry,
  JobCategory,
  ClientForm,
} from "./views/";
import InboundGroup from "./views/CallCenter/InGroup";
import LocationForm from "./views/Client/View/LocationForm";
import CreateJobForm from "./views/Job/AddUpdateForm";
import QuotePricing from "./views/QuotePricing";
import VerifiedLogin from "./views/Login/VerifiedLogin";
import ForgotPassword from "./views/Login/forgetPassword";
import ConfirmPassword from "./views/Login/confirmPassword";
import Campaigns from "./views/CallCenter/Campaigns";
import DIDsGroups from "./views/CallCenter/DIDs";
import Scheduler from "./views/Scheduler";
import JobsOnMap from "./views/Map";
import MapWithRadius from "./views/Map/GoogleMap";
import Vendors from "./views/Vendors";
import RealTime from "./views/RealTime";
import ReportsDashboard from "./reports";
import CommissionReport from "./reports/Commision";
import AgentlessReport from "./reports/Agentless";
import ClosingReport from "./reports/Closing";
import LastTouchReport from "./reports/LastTouch";
import MultiLoginReport from "./reports/MultiLogin";
import SaleReport from "./reports/Sale";
import TransactionReport from "./reports/Transactions";
import OnlineUserReport from "./reports/OnlineUsers";
import GeneralSettings from "./views/GeneralSetting";
import Providers from "./views/Provider";
import CallCenterMenu from "./views/CallCenter";
import CallCenters from "./views/CallCenter/CallCenters";
import Tasks from "./views/Tasks";
import Dashboard from "./views/Dashboard";
import StatisticsReports from "./reports/StatisticsReports";
import Balances from "./reports/Balances";
import GeoLocation from "./views/GeoLocation/GeoLocation";
import GeoIndustry from "./views/GeoIndustry";
import GeoVendors from "./views/GeoVendors";
import GeoLocationList from "./views/GeoLocation";
import GeoReport from "./views/GeoLocation/Report";
import DistanceTable from "./views/GeoLocation/DistanceTable";
import GeoStates from "./views/GeoStates";
import GeoCities from "./views/GeoCities";
import ScrubDomains from "./views/GeoLocation/ScrubDomains";
import AgentPerformance from "./views/CallCenter/Reports/AgentPerformanceReport";
import CallSummary from "./views/CallCenter/Reports/CallSummary";
import ScrubReport from "./views/GeoLocation/ScrubReport";
import Tlds from "./views/Tlds";
import Registrar from "./views/Registrar";
import DomainBuying from "./views/DomainCenter/Buying";
import InBoundReport from "./views/CallCenter/Reports/InBound";
import BatchReport from "./views/DomainCenter/BatchReport";
import BatchViewModal from "./views/DomainCenter/BatchReport/ViewBatch";
import AssignTech from "./views/GeoLocation/AssignTech";
import ServiceAreas from "./views/GeoIndustry/ServiceAreas";
import VendorsIndustry from "./views/VendorIndustry";
import UpdateVendorsIndustry from "./views/VendorIndustry/AddUpdatePage";
import UpdateVendorsLocations from "./views/Vendors/UpdateLocations";
import MapComponent from "./views/Map/GoogleMao2";
import Conversation from "./views/Conversation";
import CompnaySummary from "./reports/8x8/CompnaySummary";
import ExtensionSummary from "./reports/8x8/ExtensionSummary";
import CallDetailRecords from "./reports/8x8/CallDetailRecords";
import CallsByDIDs from "./reports/8x8/CallsByDIDs";
import VendorLocationsReport from "./reports/Domains/VendorLocationsReport";
import DomainDataReport from "./reports/Domains/DomainDataReport";
import OOAStatusReport from "./reports/OOA";
import GoogleConsole from "./views/GoogleConsoleList";
import MajorCities from "./reports/Domains/MajorCities";
import MultipleDomain from "./views/DomainCenter/MultipleDomains";
import DomainLocationReport from "./views/DomainCenter/DomainsLocationDetail";
import TestingLinks from "./views/DomainCenter/TestLinks";
import MissedCallsReport from "./views/CallCenter/Reports/MissedCalls";
import PurchaseRegistrar from "./views/DomainCenter/PurchaseRegistrar";
import PurchaseRegistrarAccounts from "./views/DomainCenter/PurchaseRegistrarAccounts";
import APIsReport from "./reports/APIsReport";
import IndustrySummary from "./views/Industries/IndustrySummary";
import DomainRanking from "./views/DomainCenter/DomainRanking";
import Settings from "./views/Settings";
import Cloudflare from "./views/Cloudflare/GenerateEmail";
import EmailPrefix from "./views/Cloudflare/EmailPrefix";
import ForwardEmail from "./views/Cloudflare/ForwardEmail";
import DomainEmailList from "./views/Cloudflare/DomainEmailList";
import DomainsListing from "./views/DomainCenter/Domains";
import RestrictedWords from "./views/RestrictedWords";
import GoogleEmailAccounts from "./views/GoogleEmailAccounts";
import GoogleServer from "./views/Servers";
import CallMenu from "./views/CallCenter/CallMenu";
import AudioUpload from "./views/CallCenter/AudioUpload";
import UpdateCallMenu from "./views/CallCenter/CallMenu/UpdateCallMenu";
import CronFunctions from "./views/CronFunctions";
import IndustryDatabase from "./views/IndustryDatabase";
import TestingDomains from "./views/DomainCenter/TestingDomains";
import NationSites from "./views/NationSites";
import NationDidListing from "./views/NationSites/DidListing";
import NationTollfreeDidListing from "./views/NationSites/TollfreeDidListing";
import DomainRunner from "./views/DomainRunners";
import RegistrarAccounts from "./views/DomainCenter/RegistrarAccounts";
import AlternateDomainPhone from "./views/DomainCenter/AlternateDomainsPhone";
import DomainNumbers from "./views/DomainCenter/DomainNumbers";
import VirtualHost from "./views/DomainCenter/VirtualHost";
import PendingDomainsPhone from "./views/DomainCenter/PendingDomainsPhone";
import NamesiloReport from "./views/DomainCenter/Namsilo";
import PullToServer from "./views/DomainCenter/PullToServer";
import DetachDomain from "./views/DomainCenter/DetachDomain";
import DomainsActiveReport from "./views/DomainCenter/DomainsActiveReport";
import DomainIndexReport from "./views/DomainCenter/DomainsIndexReport";
import NationSiteZips from "./views/NationSiteZips";
import DatabaseTables from "./views/IndustryDatabase/DatabaseTables";
import DatabaseRows from "./views/IndustryDatabase/DatabaseRows";
import SpamNumbers from "./views/CallCenter/SpamNumbers";
import DomainKeywords from "./views/DomainCenter/SERPReport/DomainRank/DomainKeywords";
import KeywordsBatches from "./views/DomainCenter/SERPReport/DomainRank/KeywordBatches";
import SERPKeywordsSearches from "./views/DomainCenter/SERPReport/DomainRank/Searches";
import ExpireDomains from "./views/DomainCenter/ExpiredDomains";
import Domains from "./views/DomainCenter/SERPReport/DomainRank/Domains";
import InBoundForwardingReport from "./views/CallCenter/Reports/InBoundForwarding";
import InvoiceReport from "./reports/InvoiceReport";
import InteliquentNumbers from "./views/DomainCenter/InteliquentNumbers";
import UserGroups from "./views/UserGroups";
import VendorBalance from "./reports/VendorBalance";
import LeadSource from "./views/LeadSource";
import LeadSourceGroup from "./views/LeadSourceGroup";
import AddUpdateLeadSouce from "./views/LeadSource/AddUpdatePage";
import WebQueryReport from "./reports/WebQueryReport";
import LeadStatus from "./views/LeadStatus";
import LeadActions from "./views/LeadAction";
import VendorJobsReport from "./reports/VendorJobsReport";
import DIDsInventory from "./views/DIDInventory";
import DocusignTemplates from "./views/VerifiedCrm/DocusignTemplates";
const AddUpdatePage = React.lazy(() =>
  import("./views/VerifiedLead/AddUpdatePage")
);
const LeadAddEditWrapper = () => {
  const location = useLocation();
  const key = location.pathname + location.search;

  return <AddUpdatePage key={key} />;
};
const Router = () => {
  const { user } = useSelector((state) => state.auth);
  const permissions = [
    ...(user?.menu_permissions || []),
    ...(user?.permissions || []),
  ];
  let routes;
  if (!user) {
    routes = [
      { path: "/login", element: <VerifiedLogin /> },
      { path: "/login2", element: <Login /> },
      { path: "/client/service_form", element: <ClientForm /> },
      { path: "/client/location_form", element: <LocationForm /> },
      { path: "/forgot_password", element: <ForgotPassword /> },
      { path: "/reset/:token", element: <ConfirmPassword /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ];
  } else {
    routes = [
      {
        path: "/map2",
        element: <MapWithRadius />,
        permission: "Map",
        component: React.lazy(() => import("./views/Map/GoogleMap")),
      },
      {
        path: "/map3",
        element: <MapComponent />,
        permission: "Map",
        component: React.lazy(() => import("./views/Map/GoogleMao2")),
      },
      {
        path: "/leads",
        element: <Lead />,
        permission: "Lead",
        component: React.lazy(() => import("./views/Lead")),
      },
      {
        path: "/leads/add",
        element: <ClientForm />,
        permission: "Lead",
        component: React.lazy(() => import("./views/Client/View/ServiceForm")),
      },
      {
        path: "/conversations",
        element: <Conversation />,
        permission: "Conversations",
        component: React.lazy(() => import("./views/Conversation")),
      },
      {
        path: "/leads/update/:id",
        element: <ClientForm />,
        permission: "Lead",

        component: React.lazy(() => import("./views/Client/View/ServiceForm")),
      },
      {
        path: "/calendar",
        element: <Scheduler />,
        permission: "Calendar",
        component: React.lazy(() => import("./views/Scheduler")),
      },
      {
        path: "/products",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Products")),
      },
      {
        path: "/map",
        element: <JobsOnMap />,
        permission: "Map",
        component: React.lazy(() => import("./views/Map")),
      },
      {
        path: "/industry_database",
        element: <IndustryDatabase />,
        permission: "Industry Database",
        component: React.lazy(() => import("./views/IndustryDatabase")),
      },
      {
        path: "/industry_database/:dbName",
        element: <DatabaseTables />,
        permission: "Industry Database",
        component: React.lazy(() =>
          import("./views/IndustryDatabase/DatabaseTables")
        ),
      },
      {
        path: "/industry_database/:dbName/:tableName/:tagName?",
        element: <DatabaseRows />,
        permission: "Industry Database",
        component: React.lazy(() =>
          import("./views/IndustryDatabase/DatabaseRows")
        ),
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        permission: "Dashboard",
        component: React.lazy(() => import("./views/Dashboard")),
      },
      {
        path: "/jobs",
        element: <Job />,
        component: React.lazy(() => import("./views/Job")),
        permission: "Job",
      },
      { path: "/dashboard", element: <Dashboard />, permission: "Dashboard" },
      { path: "/jobs", element: <Job />, permission: "Jobs" },
      {
        path: `/jobs?searchBy=:searchBy&searchValue=:searchValue`,
        element: <Job />,
        permission: "Jobs",
      },

      { path: "/jobs/create", element: <CreateJobForm />, permission: "Jobs" },
      {
        path: "/jobs/update/:id",
        element: <CreateJobForm />,
        permission: "Jobs",
      },
      {
        path: "/jobs/update/preview/:id",
        element: <CreateJobForm />,
        permission: "Jobs",
      },

      {
        path: "/settings/allowedIp",
        element: <AllowedIp />,
        permission: "Allowed IP",
        component: React.lazy(() => import("./views/AllowedIP")),
      },
      {
        path: "/settings/users",
        element: <User />,
        permission: `${user?.company_name !== "Verified CRM" ? "User" : "All"}`,
        component: React.lazy(() => import("./views/User")),
      },
      {
        path: "/settings/user_groups",
        element: <UserGroups />,
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups")),
      },
      {
        path: "/settings/user_groups/assign_user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups/AssignUsers")),
      },
      {
        path: "/settings/vendors",
        element: <Vendors />,
        permission: "Vendors",
        component: React.lazy(() => import("./views/Vendors")),
      },
      {
        path: "/settings/vendors/add",
        element: <UpdateVendorsLocations />,
        permission: "Vendors",
        component: React.lazy(() => import("./views/Vendors/UpdateLocations")),
      },
      {
        path: "/settings/vendors/update/:id",
        element: <UpdateVendorsLocations />,
        permission: "Vendors",
        component: React.lazy(() => import("./views/Vendors/UpdateLocations")),
      },
      {
        path: "/settings/vendors_industry",
        element: <VendorsIndustry />,
        permission: "Vendors",
        component: React.lazy(() => import("./views/VendorIndustry")),
      },
      {
        path: "/settings/vendors_industry_update",
        element: <UpdateVendorsIndustry />,
        permission: "Vendors",
        component: React.lazy(() =>
          import("./views/VendorIndustry/AddUpdatePage")
        ),
      },
      {
        path: "/settings/merchants",
        element: <Merchant />,
        permission: "Merchants",
        component: React.lazy(() => import("./views/Merchant")),
      },
      {
        path: "/settings/tags",
        element: <Tag />,
        permission: "Tags",
        component: React.lazy(() => import("./views/Tag")),
      },
      {
        path: "/settings/administration/emailTemplates",
        element: <EmailTemplate />,
        permission: "Email Templates",
        component: React.lazy(() => import("./views/EmailTemplate")),
      },
      {
        path: "/settings/paymentGateway",
        element: <PaymentGateway />,
        permission: "Payment Gateway",
        component: React.lazy(() => import("./views/PaymentGateway")),
      },
      {
        path: "/settings/jobStatus",
        element: <JobStatus />,
        permission: "Job Status",
        component: React.lazy(() => import("./views/JobStatus")),
      },
      {
        path: "/settings/smsTemplates",
        element: <SMSTemplate />,
        permission: "SMS Templates",
        component: React.lazy(() => import("./views/SMSTemplate")),
      },
      {
        path: "/settings/declineReasons",
        element: <DeclineReason />,
        permission: "Decline Reason",
        component: React.lazy(() => import("./views/DeclineReason")),
      },
      {
        path: "/settings/roles",
        element: <Roles />,
        permission: "User Roles",
        component: React.lazy(() => import("./views/Roles")),
      },
      {
        path: "/settings/tasks",
        element: <Tasks />,
        permission: "User Permissions",
        component: React.lazy(() => import("./views/Tasks")),
      },
      {
        path: "/settings/cardtype",
        element: <CardType />,
        permission: "Card Types",
        component: React.lazy(() => import("./views/Cardtype")),
      },
      {
        path: "/settings/industries",
        element: <Industry />,
        permission: "Industry",
        component: React.lazy(() => import("./views/Industries")),
      },
      {
        path: "/settings/jobCategory",
        element: <JobCategory />,
        permission: "Industry Category",
        component: React.lazy(() => import("./views/JobCategory")),
      },
      {
        path: "/settings/generalSettings",
        element: <GeneralSettings />,
        permission: "General Settings",
        component: React.lazy(() => import("./views/Settings")),
      },
      {
        path: "/settings/providers",
        element: <Providers />,
        permission: "Payment Providers",
        component: React.lazy(() => import("./views/Provider")),
      },
      {
        path: "/settings/quote_pricing",
        element: <QuotePricing />,
        permission: "Quoting",
        component: React.lazy(() => import("./views/QuotePricing")),
      },
      {
        path: "/settings/restricted_words",
        element: <RestrictedWords />,
        permission: "Restricted Words",
        component: React.lazy(() => import("./views/RestrictedWords")),
      },
      {
        path: "/settings/cron_functions",
        element: <CronFunctions />,
        permission: "Cron Functions",
        component: React.lazy(() => import("./views/CronFunctions")),
      },

      {
        path: "/callcenter",
        element: <CallCenterMenu />,
        permission: "Call Center",
        component: React.lazy(() => import("./views/CallCenter")),
      },
      {
        path: "/settings",
        element: <Settings />,
        permission: "Settings",
        component: React.lazy(() => import("./views/Settings")),
      },
      {
        path: "/callcenter/inbound_groups",
        element: <InboundGroup />,
        permission: "Ring Groups",
        component: React.lazy(() => import("./views/CallCenter/InGroup")),
      },
      {
        path: "/callcenter/campaigns",
        element: <Campaigns />,
        permission: "Campaigns",
        component: React.lazy(() => import("./views/CallCenter/Campaigns")),
      },
      {
        path: "/callcenter/dids",
        element: <DIDsGroups />,
        permission: "DIDs",
        component: React.lazy(() => import("./views/CallCenter/DIDs")),
      },
      {
        path: "/callcenter/call_centers",
        element: <CallCenters />,
        permission: "Call Centers",
        component: React.lazy(() => import("./views/CallCenter/CallCenters")),
      },
      {
        path: "/callcenter/audio_files",
        element: <AudioUpload />,
        permission: "Audio Files",
        component: React.lazy(() => import("./views/CallCenter/AudioUpload")),
      },
      {
        path: "/callcenter/call_menu",
        element: <CallMenu />,
        permission: "Call Menu",
        component: React.lazy(() => import("./views/CallCenter/CallMenu")),
      },
      {
        path: "/callcenter/call_menu/:id",
        element: <UpdateCallMenu />,
        permission: "Call Menu",
        component: React.lazy(() =>
          import("./views/CallCenter/CallMenu/UpdateCallMenu")
        ),
      },
      {
        path: "/callcenter/spam_numbers",
        element: <SpamNumbers />,
        permission: "Spam Numbers",
        component: React.lazy(() => import("./views/CallCenter/SpamNumbers")),
      },

      {
        path: "callcenter/reports/agent_performance_report",
        element: <AgentPerformance />,
        permission: "Agent Performance",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/AgentPerformanceReport")
        ),
      },
      {
        path: "callcenter/reports/call_summary_report",
        element: <CallSummary />,
        permission: "Call Summary",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/CallSummary")
        ),
      },
      {
        path: "callcenter/reports/call_logs",
        element: <InBoundReport />,
        permission: "Call Logs",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/InBound")
        ),
      },
      {
        path: "callcenter/reports/inbound_forwarding_report",
        element: <InBoundForwardingReport />,
        permission: "Inbound Forwarding",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/InBoundForwarding")
        ),
      },
      {
        path: "callcenter/reports/missedcall_report",
        element: <MissedCallsReport />,
        permission: "Missed Call Report",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/MissedCalls")
        ),
      },

      {
        path: "/reports/callcenter/reports/agent_performance_report",
        element: <AgentPerformance />,
        permission: "Agent Performance",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/AgentPerformanceReport")
        ),
      },
      {
        path: "/reports/callcenter/reports/call_summary_report",
        element: <CallSummary />,
        permission: "Call Summary",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/CallSummary")
        ),
      },
      {
        path: "/reports/callcenter/reports/call_logs",
        element: <InBoundReport />,
        permission: "Call Logs",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/InBound")
        ),
      },
      {
        path: "/reports/callcenter/reports/missedcall_report",
        element: <MissedCallsReport />,
        permission: "Missed Call Report",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/MissedCalls")
        ),
      },
      {
        path: "/client/service_form",
        element: <ClientForm />,
        permission: "Lead",
        component: React.lazy(() => import("./views/Client/View/ServiceForm")),
      },
      {
        path: "/client/location_form",
        element: <LocationForm />,
        permission: "Lead",
        component: React.lazy(() => import("./views/Client/View/LocationForm")),
      },
      {
        path: "/reports",
        element: <ReportsDashboard />,
        permission: "All",
        component: React.lazy(() => import("./reports")),
      },
      {
        path: "/reports/loginLogs",
        element: <LoginLogs />,
        permission: "All",
        component: React.lazy(() => import("./reports/Login")),
      },
      {
        path: "/reports/commission",
        element: <CommissionReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/Commision")),
      },
      {
        path: "/reports/vendors_invoice",
        element: <InvoiceReport />,
        permission: "All",
      },
      {
        path: "/reports/job_progress_report",
        element: <VendorJobsReport />,
        permission: "All",
      },
      {
        path: "/reports/vendors_balance",
        element: <VendorBalance />,
        permission: "All",
      },
      {
        path: "/reports/agentless",
        element: <AgentlessReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/Agentless")),
      },
      {
        path: "/reports/closing",
        element: <ClosingReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/Closing")),
      },
      {
        path: "/reports/last_touch",
        element: <LastTouchReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/LastTouch")),
      },
      {
        path: "/reports/multi_login",
        element: <MultiLoginReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/MultiLogin")),
      },
      {
        path: "/reports/sale",
        element: <SaleReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/Sale")),
      },
      {
        path: "/reports/transaction",
        element: <TransactionReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/Transactions")),
      },
      {
        path: "/reports/online_users",
        element: <OnlineUserReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/OnlineUsers")),
      },
      {
        path: "/reports/statistics_reports",
        element: <StatisticsReports />,
        permission: "All",
        component: React.lazy(() => import("./reports/StatisticsReports")),
      },

      {
        path: "/reports/company_summary",
        element: <CompnaySummary />,
        permission: "All",
        component: React.lazy(() => import("./reports/8x8/CompnaySummary")),
      },
      {
        path: "/reports/extension_summary",
        element: <ExtensionSummary />,
        permission: "All",
        component: React.lazy(() => import("./reports/8x8/ExtensionSummary")),
      },
      {
        path: "/reports/call_detail_records",
        element: <CallDetailRecords />,
        permission: "All",
        component: React.lazy(() => import("./reports/8x8/CallDetailRecords")),
      },
      {
        path: "/reports/calls_by_did",
        element: <CallsByDIDs />,
        permission: "All",
        component: React.lazy(() => import("./reports/8x8/CallsByDIDs")),
      },
      {
        path: "/reports/vendor_location_reports",
        element: <VendorLocationsReport />,
        permission: "All",
        component: React.lazy(() =>
          import("./reports/Domains/VendorLocationsReport")
        ),
      },
      {
        path: "/reports/domain_data_report",
        element: <DomainDataReport />,
        permission: "All",
        component: React.lazy(() =>
          import("./reports/Domains/DomainDataReport")
        ),
      },
      {
        path: "/reports/ooa_status_report",
        element: <OOAStatusReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/OOA")),
      },
      {
        path: "/reports/major_cities",
        element: <MajorCities />,
        permission: "All",
        component: React.lazy(() => import("./reports/Domains/MajorCities")),
      },
      {
        path: "/reports/api_report",
        element: <APIsReport />,
        permission: "All",
        component: React.lazy(() => import("./reports/APIsReport")),
      },
      {
        path: "/reports/balances",
        element: <Balances />,
        permission: "All",
        component: React.lazy(() => import("./reports/Balances")),
      },
      {
        path: "/reports/web_query_report",
        element: <WebQueryReport />,
        permission: "All",
      },
      {
        path: "/geolocation/locations_list",
        element: <GeoLocationList />,
        permission: "Locations",
        component: React.lazy(() => import("./views/GeoLocation")),
      },
      {
        path: "/geolocation/locations",
        element: <GeoLocation />,
        permission: "Locations",
        component: React.lazy(() => import("./views/GeoLocation/GeoLocation")),
      },
      {
        path: "/geolocation/geo_industry",
        element: <GeoIndustry />,
        permission: "Geo Location",
        component: React.lazy(() => import("./views/GeoIndustry")),
      },
      {
        path: "/geolocation/geo_industry/service_area/:id",
        element: <ServiceAreas />,
        permission: "Geo Location",
        component: React.lazy(() => import("./views/GeoIndustry/ServiceAreas")),
      },
      {
        path: "/geolocation/geo_vendors",
        element: <GeoVendors />,
        permission: "Geo Location",
        component: React.lazy(() => import("./views/GeoVendors")),
      },
      {
        path: "/realtime",
        element: <RealTime />,
        permission: "Real Time",
        component: React.lazy(() => import("./views/RealTime")),
      },
      {
        path: "/update/geolocation/locations",
        element: <GeoLocation />,
        permission: "Locations",
        component: React.lazy(() => import("./views/GeoLocation/GeoLocation")),
      },
      {
        path: "/geolocation/geo_location_report",
        element: <GeoReport />,
        permission: "Report",
        component: React.lazy(() => import("./views/GeoLocation/Report")),
      },
      {
        path: "/geolocation/check_distance",
        element: <DistanceTable />,
        permission: "Check Distance",
        component: React.lazy(() =>
          import("./views/GeoLocation/DistanceTable")
        ),
      },
      {
        path: "/geolocation/geo_states",
        element: <GeoStates />,
        permission: "States",
        component: React.lazy(() => import("./views/GeoStates")),
      },
      {
        path: "/geolocation/geo_cities",
        element: <GeoCities />,
        permission: "Cities",
        component: React.lazy(() => import("./views/GeoCities")),
      },
      {
        path: "/geolocation/scrub_domains",
        element: <ScrubDomains />,
        permission: "Scrub Domains",
        component: React.lazy(() => import("./views/GeoLocation/ScrubDomains")),
      },
      {
        path: "/geolocation/scrub_report",
        element: <ScrubReport />,
        permission: "Scrub Domains",
        component: React.lazy(() => import("./views/GeoLocation/ScrubReport")),
      },
      {
        path: "/geolocation/assign_tech/:id",
        element: <AssignTech />,
        permission: "Locations",
        component: React.lazy(() => import("./views/GeoLocation/AssignTech")),
      },

      {
        path: "/nation_site/sites",
        element: <NationSites />,
        permission: "Site Listing",
        component: React.lazy(() => import("./views/NationSites")),
      },
      {
        path: "/nation_site/sites/dids/:id",
        element: <NationDidListing />,
        permission: "Site Listing",
        component: React.lazy(() => import("./views/NationSites/DidListing")),
      },
      {
        path: "/nation_site/sites/tollfree/:id",
        element: <NationTollfreeDidListing />,
        permission: "Site Listing",
        component: React.lazy(() =>
          import("./views/NationSites/TollfreeDidListing")
        ),
      },
      {
        path: "/domain_center/tlds",
        element: <Tlds />,
        permission: "TLDs",
        component: React.lazy(() => import("./views/Tlds")),
      },
      {
        path: "/nation_site/nation_site_zips",
        element: <NationSiteZips />,
        permission: "Nation Site Zips",
        component: React.lazy(() => import("./views/NationSiteZips")),
      },
      {
        path: "/domain_center/registrar",
        element: <Registrar />,
        permission: "Registrar",
        component: React.lazy(() => import("./views/Registrar")),
      },
      {
        path: "/domain_center/purchase_registrar",
        element: <PurchaseRegistrar />,
        permission: "Purchased Registrar",
        component: React.lazy(() =>
          import("./views/DomainCenter/PurchaseRegistrar")
        ),
      },
      {
        path: "/domain_center/runners",
        element: <DomainRunner />,
        permission: "Purchased Registrar",
        component: React.lazy(() => import("./views/DomainRunners")),
      },
      {
        path: "/domain_center/purchase_registrar_accounts",
        element: <PurchaseRegistrarAccounts />,
        permission: "Registrar Accounts",
        component: React.lazy(() =>
          import("./views/DomainCenter/PurchaseRegistrarAccounts")
        ),
      },
      {
        path: "/domain_center/buying",
        element: <DomainBuying />,
        permission: "Buying",
        component: React.lazy(() => import("./views/DomainCenter/Buying")),
      },
      {
        path: "/domain_center/pull_to_server",
        element: <PullToServer />,
        permission: "Pull to Server",
        component: React.lazy(() =>
          import("./views/DomainCenter/PullToServer")
        ),
      },
      {
        path: "/domain_center/gsc_email_accounts",
        element: <GoogleEmailAccounts />,
        permission: "Email Accounts",
        component: React.lazy(() => import("./views/GoogleEmailAccounts")),
      },
      {
        path: "/domain_center/servers",
        element: <GoogleServer />,
        permission: "Server",
        component: React.lazy(() => import("./views/Servers")),
      },
      {
        path: "/domain_center/inteliquent_numbers",
        element: <InteliquentNumbers />,
        permission: "Inteliquent Numbers",
        component: React.lazy(() =>
          import("./views/DomainCenter/InteliquentNumbers")
        ),
      },
      {
        path: "/domain_center/batch_report",
        element: <BatchReport />,
        permission: "Batches",
        component: React.lazy(() => import("./views/DomainCenter/BatchReport")),
      },
      {
        path: "/domain_center/batch_report/:id",
        element: <BatchViewModal />,
        permission: "Batches",
        component: React.lazy(() =>
          import("./views/DomainCenter/BatchReport/ViewBatch")
        ),
      },

      {
        path: "/domain_center/domain_ranking",
        element: <DomainRanking />,
        permission: "Domain Ranking",
        component: React.lazy(() =>
          import("./views/DomainCenter/DomainRanking")
        ),
      },
      {
        path: "/test",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead/testHtml")),
      },
      {
        path: "/pdftohtml",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead/PdfHtml")),
      },
      {
        path: "/google_console_list",
        element: <GoogleConsole />,
        permission: "Google Credentials",
        component: React.lazy(() => import("./views/GoogleConsoleList")),
      },
      {
        path: "/domain_center/multiple_domains",
        element: <MultipleDomain />,
        permission: "Multiple Domains",
        component: React.lazy(() =>
          import("./views/DomainCenter/MultipleDomains")
        ),
      },
      {
        path: "/domain_rank/searches/:batchId?",
        element: <SERPKeywordsSearches />,
        permission: "Searches",
        component: React.lazy(() =>
          import("./views/DomainCenter/SERPReport/DomainRank/Searches")
        ),
      },
      {
        path: "/domain_rank/keywords",
        element: <DomainKeywords />,
        permission: "Keywords",
        component: React.lazy(() =>
          import("./views/DomainCenter/SERPReport/DomainRank/DomainKeywords")
        ),
      },
      {
        path: "/domain_rank/batches_list",
        element: <KeywordsBatches />,
        permission: "Batches",
        component: React.lazy(() =>
          import("./views/DomainCenter/SERPReport/DomainRank/KeywordBatches")
        ),
      },
      {
        path: "/domain_rank/domains",
        element: <Domains />,
        permission: "Domains",
        component: React.lazy(() =>
          import("./views/DomainCenter/SERPReport/DomainRank/Domains")
        ),
      },
      {
        path: "/domain_center/purchased_domains",
        element: <DomainsListing />,
        permission: "Purchased Domains",
        component: React.lazy(() => import("./views/DomainCenter/Domains")),
      },
      {
        path: "/domain_center/pending_domains_phone",
        element: <PendingDomainsPhone />,
        permission: "Pending Phones",
        component: React.lazy(() =>
          import("./views/DomainCenter/PendingDomainsPhone")
        ),
      },
      {
        path: "/domain_center/domain_location_report",
        element: <DomainLocationReport />,
        permission: "Domains Location",
        component: React.lazy(() =>
          import("./views/DomainCenter/DomainsLocationDetail")
        ),
      },
      {
        path: "/domain_center/testing_links",
        element: <TestingLinks />,
        permission: "Domains Location",
        component: React.lazy(() => import("./views/DomainCenter/TestLinks")),
      },
      {
        path: "/domain_center/testing_domains",
        element: <TestingDomains />,
        permission: "Testing Domains",
        component: React.lazy(() =>
          import("./views/DomainCenter/TestingDomains")
        ),
      },
      {
        path: "/domain_center/cloudflare_accounts",
        element: <RegistrarAccounts />,
        permission: "Cloudflare Accounts",
        component: React.lazy(() =>
          import("./views/DomainCenter/RegistrarAccounts")
        ),
      },
      {
        path: "/domain_center/domain_active_report",
        element: <DomainsActiveReport />,
        permission: "Domains Active Report",
        component: React.lazy(() =>
          import("./views/DomainCenter/DomainsActiveReport")
        ),
      },
      {
        path: "/domain_center/domain_index_report",
        element: <DomainIndexReport />,
        permission: "Domains Index Report",
        component: React.lazy(() =>
          import("./views/DomainCenter/DomainsIndexReport")
        ),
      },
      {
        path: "/domain_center/list_alternate_area_codes",
        element: <AlternateDomainPhone />,
        permission: "Alternate Area Codes",
        component: React.lazy(() =>
          import("./views/DomainCenter/AlternateDomainsPhone")
        ),
      },
      {
        path: "/domain_center/domain_numbers",
        element: <DomainNumbers />,
        permission: "Domain Numbers",
        component: React.lazy(() =>
          import("./views/DomainCenter/DomainNumbers")
        ),
      },
      {
        path: "/domain_center/virtual_host",
        element: <VirtualHost />,
        permission: "Virtual Hosts",
        component: React.lazy(() => import("./views/DomainCenter/VirtualHost")),
      },
      {
        path: "/domain_center/namesilo_domains_report",
        element: <NamesiloReport />,
        permission: "Namesilo Domain Rpt",
        component: React.lazy(() => import("./views/DomainCenter/Namsilo")),
      },
      {
        path: "/domain_center/detach_domains",
        element: <DetachDomain />,
        permission: "Detach Domains",
        component: React.lazy(() =>
          import("./views/DomainCenter/DetachDomain")
        ),
      },
      {
        path: "/cloudflare/generate_email",
        element: <Cloudflare />,
        permission: "Generate Emails",
        component: React.lazy(() => import("./views/Cloudflare/GenerateEmail")),
      },
      {
        path: "/cloudflare/email_prefix",
        element: <EmailPrefix />,
        permission: "Email Prefix",
        component: React.lazy(() => import("./views/Cloudflare/EmailPrefix")),
      },
      {
        path: "/cloudflare/forward_email",
        element: <ForwardEmail />,
        permission: "Forward Emails",
        component: React.lazy(() => import("./views/Cloudflare/ForwardEmail")),
      },
      {
        path: "/cloudflare/domain_email_list",
        element: <DomainEmailList />,
        permission: "Domain Emails List",
        component: React.lazy(() =>
          import("./views/Cloudflare/DomainEmailList")
        ),
      },
      {
        path: "/domain_center/expired_domains",
        element: <ExpireDomains />,
        permission: "Expired Domains",
        component: React.lazy(() =>
          import("./views/DomainCenter/ExpiredDomains")
        ),
      },
      {
        path: "/settings/industries/industry_domains_report/:id",
        element: <IndustrySummary />,
        permission: "Industry",
        component: React.lazy(() =>
          import("./views/Industries/IndustrySummary")
        ),
      },
      {
        path: "/settings/lead_source",
        element: <LeadSource />,
        permission: "Lead Source",
        component: React.lazy(() => import("./views/LeadSource")),
      },
      {
        path: "/settings/lead_source/leadSource_AddEdit",
        element: <AddUpdateLeadSouce />,
        permission: "Lead Source",
        component: React.lazy(() => import("./views/LeadSource/AddUpdatePage")),
      },
      {
        path: "/settings/lead_source_group",
        element: <LeadSourceGroup />,
        permission: "Lead Source Group",
        component: React.lazy(() => import("./views/LeadSourceGroup")),
      },
      {
        path: "/settings/administration/lead_status",
        element: <LeadStatus />,
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus")),
      },
      {
        path: "/settings/administration/lead_status/assign_lead_actions",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus/AssignActions")),
      },
      {
        path: "/settings/administration/lead_status/assign_lead_email",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus/AssignEmail")),
      },
      {
        path: "/settings/administration/lead_actions",
        element: <LeadActions />,
        permission: "All",
        component: React.lazy(() => import("./views/LeadAction")),
      },
      {
        path: "/settings/administration/users",
        element: <User />,
        permission: "Users & Groups",
        component: React.lazy(() => import("./views/User")),
      },
      {
        path: "/settings/administration/roles",
        element: <Roles />,
        permission: "All",
        component: React.lazy(() => import("./views/Roles")),
      },
      {
        path: "/settings/administration/user_groups",
        element: <UserGroups />,
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups")),
      },

      {
        path: "/settings/administration/user_groups/assign_user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups/AssignUsers")),
      },
      {
        path: "/order",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Order")),
      },
      {
        path: "/commission",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Commission")),
      },
      {
        path: "/crmreports",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Reports")),
      },
      {
        path: "/settings/administration/email_sms",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/EmailSms")),
      },
      {
        path: "/settings/inventory",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Inventory")),
      },
      {
        path: "/settings/inbox",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Inbox")),
      },
      {
        path: "/settings/docusign_templates",
        element: <DocusignTemplates />,
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/DocusignTemplates")
        ),
      },
      {
        path: "/settings/inbox/detail",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/Inbox/InboxDetail")
        ),
      },
      {
        path: "/settings/administration/user_permissions",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedLead/UserPermissions")
        ),
      },
      {
        path: "/settings/administration/permissions",
        permission: "All",
        component: React.lazy(() => import("./views/Permissions")),
      },
      {
        path: "/verified_lead/lead_distribution",
        permission: "All",
        component: React.lazy(() =>
          import(
            "./views/VerifiedCrm/DistributionProgram/AddUpdateLeadDistribution"
          )
        ),
      },
      {
        path: "/users/lead_distribution",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedLead/SystemDistribution")
        ),
      },

      {
        path: "/settings/lead_management/distribution_categories",
        permission: "All",
        component: React.lazy(() => import("./views/DistributionCategory")),
      },
      {
        path: "/settings/lead_management/distribution_program",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/DistributionProgram")
        ),
      },
      {
        path: "/settings/lead_management/distribution_program/distribution_program_AddEdit",
        permission: "All",
        component: React.lazy(() =>
          import(
            "./views/VerifiedCrm/DistributionProgram/AddUpdateLeadDistribution"
          )
        ),
      },
      {
        path: "/verified_lead/:lead_type?/:lead_status?/:lead_sub_status?",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead")),
      },
      {
        path: "/verified_lead?searchValue=:searchValue",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead")),
      },
      {
        path: "/customers",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Customers")),
      },
      {
        path: "/verified_lead/leads/lead_AddEdit?customer_id=:customer_id&lead_id=:lead_number",
        permission: "All",
        element: <LeadAddEditWrapper />,
        // component: React.lazy(() =>
        //   import("./views/VerifiedLead/AddUpdatePage")
        // ),
      },
      {
        path: "/verified_lead/:lead_type?/:lead_status?/:lead_sub_status?/lead_AddEdit/:lead_id?",
        permission: "All",
        element: <LeadAddEditWrapper />,

        // component: React.lazy(() =>
        //   import("./views/VerifiedLead/AddUpdatePage")
        // ),
      },
      {
        path: "/calendar_events",
        element: <Scheduler />,
        permission: "Calendar",
        component: React.lazy(() => import("./views/VerifiedCrm/Calendar")),
      },
      {
        path: "/dids_inventory",
        element: <DIDsInventory />,
        permission: "DIDs Inventory",
      },
      {
        path: "/settings/workflow_status",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/WorkflowStatus")
        ),
        permission: "All",
      },
      {
        path: "/settings/inventory/inventory_AddEdit",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/Inventory/AddUpdatInventoryPage")
        ),
      },
      {
        path: "/settings/vendor_price",
        component: React.lazy(() => import("./views/VerifiedCrm/VendorPrice")),
        permission: "All",
      },
      {
        path: "/settings/commission_spread",
        component: React.lazy(() => import("./views/VerifiedCrm/CommissionSpread")),
        permission: "All",
      },
      {
        path: "*",
        element: (
          <Navigate
            to={
              user?.role_level === 7
                ? "/industry_database"
                : user?.role_level === 4
                ? "/Conversations"
                : `/dashboard`
            }
            replace
          />
        ),
        permission: "All",
      },
    ];
  }

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="flex flex-auto flex-col h-[100vh]">
            <Loader loading={true} />
          </div>
        }
      >
        <Layout>
          <Routes>
            {user ? (
              <Route
                path="*"
                element={
                  <Navigate
                    to={
                      user?.role_level === 7 &&
                      user?.company_name !== "Verified CRM"
                        ? "/industry_database"
                        : user?.role_level === 4 &&
                          user?.company_name !== "Verified CRM"
                        ? "/Conversations"
                        : user?.company_name === "Verified CRM"
                        ? "/verified_lead/leads"
                        : `/dashboard`
                    }
                    replace
                  />
                }
              />
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}

            {routes.map((route, index) => {
              const foundPermission = permissions?.includes(route?.permission);
              const { component: Component, element } = route;
              if (foundPermission || !user || route?.permission === "All") {
                return (
                  <Route
                    key={index}
                    {...route}
                    element={Component ? <Component /> : element}
                  />
                );
              } else {
                return null;
              }
            })}
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
