import React from "react";
import { menu } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../atoms/Button";
import { FaCross, FaSearch, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { useState } from "react";
import { header_search } from "../../../features/jobsSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { toast } from "react-toastify";
import { get_dispo_drd, get_pause_drd } from "../../../features/campaignSlice";
import DialerHeader from "./DialerHeader";
import { new_logo } from "../../../images";
import TopMenu from "../Sidebar/TopMenu";
import api from "../../../services/api";
import { RxCross2 } from "react-icons/rx";
const Header = (props) => {
  const navigate = useNavigate();
  const initialFilter = {
    searchBy: "",
    searchValue: "",
  };
  const dispatch = useDispatch();
  const [searchJobs, setSearchJobs] = useState(initialFilter);
  const [menuToggle, setMenuToggle] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { status, campaign, ext } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { loader } = useSelector((state) => state.jobs);
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "180px",
      maxHeight: "31px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "31px",
      boxShadow: state.isFocused ? "none" : "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "31px",
    }),
  };
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  const searchJob = async () => {
    try {
      const res = await dispatch(header_search(searchJobs));
      if (res.payload?.status === 200) {
        if (res.payload?.data?.length === 1) {
          navigate(`/jobs/update/${res.payload?.data[0]?._id}`);
        }
        if (res.payload?.data?.length > 1) {
          navigate(
            `/jobs?searchBy=${searchJobs.searchBy}&searchValue=${searchJobs.searchValue}`
          );
        }
        if (res.payload?.data?.length === 0) {
          toast.info("No record found");
        }
      }
    } catch (err) {}
  };
  const formatPhoneNumber = (text) => {
    // Format the phone number by removing +1 or 1 if it starts with them and removing spaces, parentheses, dashes, etc.
    return text.replace(/^(\+1|1)/, '').replace(/[\s()-]/g, '');
  };
  const searchVerifiedJob = async () => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/leadActivity/list", {
        filters: {
          groupOp: "OR",
          rules: [
            {
              field: "lead_number",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.full_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.first_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.last_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.home_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.work_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.mobile_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.email",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "customer_number",
              op: "eq",
              data: searchJobs?.searchValue,
            },
          ],
        },
      });
      if (res?.status === 200) {
        if (res?.data?.leads?.length) {
          navigate(`/verified_lead?searchValue=${searchJobs.searchValue}`);
        }
        if (res?.data?.leads?.length === 0) {
          toast.info("No record found");
        }
      }
    } catch (err) {
      console.log("👊 ~ searchVerifiedJob ~ err:", err);
    }
    setIsLoader(false);
  };
  React.useEffect(() => {
    if (campaign) {
      dispatch(get_dispo_drd(campaign));
      dispatch(get_pause_drd(campaign));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  const menuToggler = () => {
    setMenuToggle(!menuToggle);
    return props.toggleNavbar(!props.show_nav);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchVerifiedJob();
    }
  };

  return (
    <>
      <header
        className={`flex flex-col w-full bg-[#f7f7f7] top-0  border-b border-[#d6dadc] fixed z-[99] justify-between transition-all ease-in-out delay-150 
      ${
        !menuToggle
          ? "md:w-[calc(100%-235px)] ml-0 md:!ml-[235px]"
          : "ml-[235px] md:w-100% md:ml-0"
      }`}
      >
        <div className="flex flex-row w-full justify-between items-center px-2 md:px-0 flex-wrap md:flex-nowrap">
          <div className="flex flex-row items-center w-ful gap-x-2 px-3">
            {/* <div className="sm:block md:block cursor-pointer">
              <img
                src={menu}
                alt="burgur"
                className="brightness-0"
                onClick={() => props.toggleNavbar(!props.show_mobilenav)}
              />
            </div>*/}
            <div className="sm:block md:block cursor-pointer">
              <img
                src={menu}
                alt="burgur"
                className="brightness-0"
                onClick={menuToggler}
              />
            </div>
            {!menuToggle ? (
              <h3 className="hidden md:block font-medium text-[#5f5f5f] text-[19px] mb2 w-auto mb-0">
                {`Welcome ${user?.first_name} `}
              </h3>
            ) : (
              ""
            )}

            {menuToggle ? (
              <TopMenu show_navlabel={true} menuToggle={menuToggle} />
            ) : null}
            {ext ? (
              <span className="font-medium text-[#5f5f5f] text-[19px]">
                Ext: {ext}
              </span>
            ) : null}
            {status ? (
              <div className="ml-3">
                <DialerHeader />
              </div>
            ) : null}
          </div>
          {/* Logo Mobile */}
          <div className="inline-block md:hidden p-2 my-2 -translate-x-[20%]">
            <Link to="/">
              <img src={new_logo} alt="Callrings CRM" className="w-[80px]" />
            </Link>
          </div>
          <button
            className="bg-[#231F20] sm:block md:hidden cursor-pointer p-1 text-center text-white leading-[16px]"
            onClick={ToggleClass}
          >
            {isActive ? <FaTimes size={16} /> : <FaSearch size={16} />}
          </button>
          {user?.company_name && user.company_name !== "Verified CRM" ? (
            <div
              className={`${
                isActive ? "flex flex-col gap-3" : "hidden"
              } md:flex md:flex-row !bg-[#f7f7f7] item-center basis-full md:basis-auto justify-end p-2.5`}
            >
              <>
                <Select
                  options={[
                    { value: "job_number", label: "Job ID" },
                    { value: "card_last_4_digits", label: "Last Digit of CC" },
                    { value: "name", label: "Customer Name" },
                    { value: "phone", label: "Phone" },
                    { value: "po_insurance", label: "PO Number" },
                  ]}
                  placeholder="Search By"
                  className="w-full md:w-1/2 !mr-2"
                  onChange={(e) =>
                    setSearchJobs({ ...searchJobs, searchBy: e.value })
                  }
                />
                <div className="flex flex-row border-1 border-gray-300 rounded-[0.2rem] md:border-none md:p-0">
                  <input
                    style={style}
                    type="text"
                    className="flex-1 md:w-1/2 md:!mr-2 max-h-[38px] bg-[#fff] border text-gray-900 text-sm md:bg-white border-gray-300 md:rounded-[0.2rem] p-2.5"
                    placeholder="Search"
                    value={props.searchText}
                    onChange={(e) => {
                      setSearchJobs({
                        ...searchJobs,
                        searchValue: e.target.value,
                      });
                    }}
                  />
                  <Button
                    text={
                      loader.headerSearch ? (
                        <Loader size={5} width={20} height={20} color="#fff" />
                      ) : (
                        <FaSearch size={20} />
                      )
                    }
                    variant="btn_submit"
                    className="max-h-[38px] !bg-[#042A42]"
                    onClick={searchJob}
                  />
                </div>
              </>
            </div>
          ) : (
            <div
              className={`${
                isActive ? "flex flex-col gap-3" : "hidden"
              } md:flex md:flex-row !bg-[#f7f7f7] item-center basis-full md:basis-auto justify-end p-2.5`}
            >
              <>
                {/* <Select
                  options={[
                    { value: "lead_number", label: "Lead Number" },
                    {
                      value: "client_information.first_name",
                      label: "First Name",
                    },
                    {
                      value: "client_information.last_name",
                      label: "Last name",
                    },
                    {
                      value: "client_information.home_phone",
                      label: "Home Phone",
                    },
                    {
                      value: "client_information.work_phone",
                      label: "Work Phone",
                    },
                    {
                      value: "client_information.mobile_phone",
                      label: "Mobile Phone",
                    },
                  ]}
                  placeholder="Search By"
                  className="w-full md:w-1/2 !mr-2"
                  onChange={(e) =>
                    setSearchJobs({ ...searchJobs, searchBy: e.value })
                  }
                /> */}
                <div className="flex flex-row border-1 border-gray-300 rounded-[0.2rem] md:border-none md:p-0">
                  <div className="relative w-ful">
                    <input
                      style={style}
                      type="text"
                      className="relative flex-1 w-full md:w-1/ md:!mr-2 max-h-[38px] border text-gray-900 text-sm md:bg-white border-gray-300 md:rounded-[0.2rem] p-2.5"
                      placeholder="Search"
                      value={searchJobs.searchValue}
                      onChange={(e) => {
                        setSearchJobs({
                          ...searchJobs,
                          searchValue: e.target.value,
                        });
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    {searchJobs.searchValue ? (
                      <button
                        className="absolute right-1 top-[25%]"
                        onClick={() => {
                          setSearchJobs({
                            ...searchJobs,
                            searchValue: "",
                          });
                          navigate(`/verified_lead/leads`);
                        }}
                      >
                        <RxCross2 />
                      </button>
                    ) : null}
                  </div>

                  <Button
                    text={
                      loader.headerSearch || isLoader ? (
                        <Loader size={5} width={20} height={20} color="#fff" />
                      ) : (
                        <FaSearch size={20} />
                      )
                    }
                    variant="btn_submit"
                    className="max-h-[38px] !bg-[#042A42]"
                    onClick={searchVerifiedJob}
                  />
                </div>
              </>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
